import React, { useState, useMemo, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useTable, usePagination } from 'react-table';
import './AddProduct.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function AddProduct() {
  const [productData, setProductData] = useState({
    productName: '',
    description: '',
    uploadImages: [],
  });

  const [products, setProducts] = useState([]);
  const [modalImage, setModalImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); 
  const [nameError, setNameError] = useState(''); // Error state for product name
  const [descriptionError, setDescriptionError] = useState(''); // Error state for description
  const [isEditing, setIsEditing] = useState(false); // Track if editing
  const [currentProductId, setCurrentProductId] = useState(null); // Track the ID of the product being edited

  const [savedPageIndex, setSavedPageIndex] = useState(0); // Save the current page index

  const fetchProducts = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('https://vinuvehasheneagency.com/api/product');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        setProducts(data);
      } else {
        console.error('Expected an array of products, received:', data);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to load products. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "uploadImages") {
      setProductData({
        ...productData,
        [name]: Array.from(files),
      });
    } else {
      setProductData({
        ...productData,
        [name]: value,
      });
    }

    // Clear error message when the user starts typing
    if (name === 'productName') setNameError('');
    if (name === 'description') setDescriptionError('');
  };

  const handleSubmit = async () => {
    let hasError = false;

    // Reset error states
    setNameError('');
    setDescriptionError('');

    // Validation checks
    if (!productData.productName) {
      setNameError('Please enter product name.');
      hasError = true;
    }
    if (!productData.description) {
      setDescriptionError('Please enter a description.');
      hasError = true;
    }

    if (hasError) return; // Prevent submission if there are errors

    const formData = new FormData();
    formData.append('productName', productData.productName);
    formData.append('description', productData.description);
    productData.uploadImages.forEach((image) => {
      formData.append('uploadImages', image);
    });

    setLoading(true);
    setError(null);
    
    try {
      const method = isEditing ? 'PUT' : 'POST';
      const url = isEditing
        ? `https://vinuvehasheneagency.com/api/product/${currentProductId}`
        : 'https://vinuvehasheneagency.com/api/product';

      const response = await fetch(url, {
        method,
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(isEditing ? 'Product updated successfully:' : 'Product added successfully:', result);


      await fetchProducts(); // Refresh the products list
 

      // Reset form and editing state
      clearForm();
      setIsEditing(false);  // Reset to add mode after successful submission
      setCurrentProductId(null); // Clear the current product ID
    } catch (error) {
      console.error('Error submitting product:', error);
    } finally {
      setLoading(false); // Stop the loading spinner
    }
};


  const handleDelete = async (id) => {
    setLoading(true); // Set loading true
    setError(null); // Clear previous errors
    try {
      console.log(`Deleting product with ID: ${id}`);
      const response = await fetch(`https://vinuvehasheneagency.com/api/product/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data.message);

      setProducts((prevProducts) => prevProducts.filter(product => product.id !== id));
    } catch (error) {
      console.error('Error deleting product:', error);
      setError('Failed to delete product. Please try again.'); // Set error message
    } finally {
      setLoading(false); // Set loading false
    }
  };

  const handleEdit = (product) => {
    setProductData({
      productName: product.product_name,
      description: product.description,
      uploadImages: [],
    });
    setIsEditing(true);
    setCurrentProductId(product.id);
  };

  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const handleDeleteImage = async (productId, imagePath) => {
    // Extract only the file name from the full image path using string manipulation
    const imageName = imagePath.split('/').pop(); // Get the last part of the path

    try {
        const response = await fetch(`https://vinuvehasheneagency.com/api/product/${productId}/image/${imageName}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorMessage = await response.json();
            throw new Error(`Error: ${errorMessage.message}`);
        }

          // Refresh the product list after deletion
          await fetchProducts(); // Fetch the updated list of products

        console.log('Image deleted successfully');
    } catch (error) {
        console.error('Error deleting image:', error);
        setError('Failed to delete image. Please try again.'); // Set error message
    }
};


  

  const columns = useMemo(
    () => [
      {
        Header: 'Product Name',
        accessor: 'product_name',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => <div className="description-text">{value}</div>,
      },
      {
        Header: 'Images',
        accessor: 'image',
        Cell: ({ row }) => {
          const images = Array.isArray(row.original.image) ? row.original.image : [];
          return images.length > 0 ? (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {images.map((image, index) => {
                const imageUrl = `https://vinuvehasheneagency.com/api${image}`;
                return (
                  <div key={index} style={{ position: 'relative', marginRight: '5px' }}>
                    <img
                      src={imageUrl}
                      alt={`Product ${index + 1}`}
                      className="product-image"
                      style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleImageClick(imageUrl)}
                      onError={() => console.error(`Failed to load image: ${imageUrl}`)}
                    />
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      onClick={() => handleDeleteImage(row.original.id, image)}
                      className="icon text-danger"
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <span>No Images Added</span>
          );
        },
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <div>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => handleEdit(row.original)}
              className="icon text-primary me-3"
            />
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => handleDelete(row.original.id)}
              className="icon text-danger"
            />
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => products, [products]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage, // Make sure this is included
    state: { pageIndex },
    setPageIndex, // Add setPageIndex to change the page manually
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: savedPageIndex }, // Set initial pageIndex to saved value
    },
    usePagination
  );

  const imageInputRef = useRef(null);
  const clearForm = () => {
    setProductData({
      productName: '',
      description: '',
      uploadImages: [],
    });
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }
  };

  return (
    <div className="container-fluid mt-4">
      <div className="row">
        {/* Add Product Form */}
        <div className="col-md-6">
          <div className="form-container bg-light p-4 rounded shadow">
            <h2 className="text-center mb-4">Add Product</h2>
            <form>
              <div className="form-group mb-3">
                <label htmlFor="productName" className="form-label">
                  Product Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control w-100 ${nameError ? 'is-invalid' : ''}`}
                  id="productName"
                  name="productName"
                  value={productData.productName}
                  onChange={handleChange}
                  required
                />
                {nameError && <div className="invalid-feedback">{nameError}</div>}
              </div>

              <div className="form-group mb-3">
                <label htmlFor="description" className="form-label">
                  Description <span className="text-danger">*</span>
                </label>
                <textarea
                  id="description"
                  name="description"
                  className={`form-control w-100 ${descriptionError ? 'is-invalid' : ''}`}
                  value={productData.description}
                  onChange={handleChange}
                  required
                />
                {descriptionError && <div className="invalid-feedback">{descriptionError}</div>}
              </div>

              <div className="row mb-3">
                <div className="col-12 mb-2">
                  <label htmlFor="uploadImages" className="form-label">Upload Images</label>
                  <input
                    type="file"
                    id="uploadImages"
                    name="uploadImages"
                    accept="image/*"
                    className="form-control w-100"
                    multiple
                    ref={imageInputRef}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="d-grid gap-2 d-md-flex justify-content-md-between">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                  {isEditing ? 'Update Product' : 'Add Product'}
                </button>
                <button type="button" className="btn btn-secondary" onClick={clearForm}>
                  Clear
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Product List Table */}
        <div className="col-md-6">
          <div className="table-wrapper bg-light p-3 rounded shadow">
            <h3 className="text-center mb-3">Product List</h3>
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-bordered table-striped">
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Pagination Section */}
            <div className="pagination d-flex justify-content-center align-items-center mt-3">
              {/* First Page Button */}
              <button
                onClick={() => gotoPage(0)}
                className="btn btn-outline-primary btn-sm me-2"
                disabled={!canPreviousPage}
              >
                « First
              </button>

              {/* Previous Page Button */}
              <button
                onClick={previousPage}
                className="btn btn-outline-primary btn-sm me-2"
                disabled={!canPreviousPage}
              >
                ‹ Previous
              </button>

            {/* Page Input Textbox */}
            <div className="input-group input-group-sm mx-2" style={{ width: '150px' }}>
              <input
                type="text"
                className="form-control text-center"
                value={pageIndex + 1} // Display the current page number (1-based index)
                onChange={(e) => {
                  const page = e.target.value ? Math.max(0, Math.min(Number(e.target.value) - 1, pageOptions.length - 1)) : 0; // Ensure page is within range
                  gotoPage(page);
                }}
                min={1} // Minimum page number (1)
                max={pageOptions.length} // Maximum page number
                style={{ width: '100%' }} // Ensures the input takes the full width of the input group
              />
              <span className="input-group-text"> / {pageOptions.length}</span> {/* Display total pages */}
            </div>


              {/* Next Page Button */}
              <button
                onClick={nextPage}
                className="btn btn-outline-primary btn-sm ms-2"
                disabled={!canNextPage}
              >
                Next ›
              </button>

              {/* Last Page Button */}
              <button
                onClick={() => gotoPage(pageOptions.length - 1)}
                className="btn btn-outline-primary btn-sm ms-2"
                disabled={!canNextPage}
              >
                Last »
              </button>
            </div>

            {/* Image Modal */}
            {isModalOpen && (
              <div className="modal fade show" style={{ display: 'block' }} onClick={closeModal}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <img src={modalImage} alt="Preview" className="img-fluid" />
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isModalOpen && <div className="modal-backdrop fade show" onClick={closeModal} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;