import React, { useState } from 'react';
import './ForgotPassword.css'; // Adjust the path based on your file structure

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setMessage("Passwords don't match.");
      return;
    }

    try {
      // Make API call to reset the password
      const response = await fetch('https://vinuvehasheneagency.com/api/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, newPassword }), // Send both email and new password
      });

      const data = await response.json();
      setMessage(data.message || 'Password reset successfully!'); // Update success message
    } catch (error) {
      setMessage('An error occurred. Please try again later.'); // Update error message
    }
  };

  return (
    <form onSubmit={handleForgotPassword} className="container mt-4">
      <h2 className="text-center">Reset Password</h2>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">Email:</label>
        <input
          type="email"
          className="form-control form-control-sm" // Smaller input field
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="newPassword" className="form-label">New Password:</label>
        <input
          type="password"
          className="form-control form-control-sm" // Smaller input field
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="confirmPassword" className="form-label">Confirm Password:</label>
        <input
          type="password"
          className="form-control form-control-sm" // Smaller input field
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
      <div className="d-flex justify-content-center"> {/* Flexbox for centering */}
        <button type="submit" className="btn btn-primary btn-sm">Reset Password</button>
      </div>
      {message && <p className="mt-3 text-center">{message}</p>} {/* Centered message */}
    </form>
  );
};

export default ForgotPassword;
