import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Estimate from './components/Estimate';
import EstimatePage from './components/EstimatePage';
import AddProduct from './components/AddProduct';
import ForgotPassword from './components/ForgotPassword'; // Import the ForgotPassword component

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/estimate" element={<Estimate />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/estimate-page" element={<EstimatePage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} /> {/* New route */}
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

