import React, { useState, useEffect } from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import jsPDF from 'jspdf';
import './Estimate.css';

function App() {
  const [adadr, setAdadr] = useState([]);
  const [pradr, setPradr] = useState(null);
  const [taxpayerInfo, setTaxpayerInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isGstVerified, setIsGstVerified] = useState(false);  // Track GST verification
  const [selectedAgencyAddress, setSelectedAgencyAddress] = useState('');
  const initialFormData = {
    agencyAddress: '',
    partyGst: '',
    companyName: '',
    country: '',
    address: '',
    city: '',
    state: '',
    postcode: '',
    PhoneNumber: '',
    shippingAddress: '',
    DeliveryContact: '',
    ShippingAddress: '',
    district: '',
    partyState: '',
    shippingPincode: '',
    deliverContact: '',
    productSearch: '',
    unitPrice: '',  // This will hold the price per item
    qty: '',        // This will hold the quantity
    tax: '',
    hsn: '',
    totalPrice: '',
    paymentMode: '',
    customPaymentMode: '',
    shippingVehicle: '',
    bookingMode: '',
    bookingPlace: '',
    bookingTransport: '',
    description:'',
    productimage:'',
    productdocument:'',
    uploadImage: null,
    uploadPDF: null
  };
  
  const [formData, setFormData] = useState(initialFormData);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loadingDocument, setLoadingDocument] = useState(false); // Loading state for document
  const [searchQuery, setSearchQuery] = useState(''); // Declare searchQuery state
  
  
// Inside your AddProduct or Estimate component
useEffect(() => {
  const fetchProducts = async () => {
    try {
      const response = await fetch('https://vinuvehasheneagency.com/api/product');
      const data = await response.json();
      
      // Assuming 'data' contains image and document URLs
      const updatedProducts = data.map(product => ({
        ...product,
        uploadImage: product.uploadImage ? `https://vinuvehasheneagency.com/api${product.uploadImage}` : null,
      }));

      setProducts(updatedProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };
  
  fetchProducts();
}, []);

  

// Handle product selection
const handleProductSelect = (product) => {
  setSelectedProduct(product);
  setSearchQuery(''); // Clear the search query
  setSearchQuery(product.product_name); // Set the search query to the selected product name
  
  setFormData((prevState) => ({
    ...prevState,
    productModel: product.product_name,
    description: product.description || '',
    uploadImage: product.image || null,
    uploadDocument: product.document || null,
  }));
};

// Handle search input change
const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};

// Filter products based on search query
const filteredProducts = products.filter((product) =>
  product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
);

// Function to clear search and reset form data
const clearSearch = () => {
  setSearchQuery('');
  setSelectedProduct(null);
  
  // Reset form data
  setFormData({
    ...formData,
    description: '',
    uploadImage: '',
    uploadDocument: ''
  });

  // Clear selected product data
  setSelectedProduct({}); // or setSelectedProduct(null); based on your use case
};



const agencyaddresses = {
  address1: {
    name: 'VINUVEHASHENE AGENCY',
    address: '148, Tank Road, Sivananda Colony,',
    city: 'Tatabad, Coimbatore',
    state: 'Tamil Nadu',
    postcode: '641012',
    gstin: '33BYAPP1244G1ZT',
    contact: '+91 90037 02724',
    email: 'vinuvehash@gmail.com',
  },
  address2: {
    name: 'NDV COOLING EQUIPMENTS',
    address: 'SF.NO.338/1A, Site No.1',
    city: 'Introla Company Backside, Idiakari, Thudiyalur, Coimbatore',
    state: 'Tamil Nadu',
    postcode: '641022',
    gstin: '33ALJPV7155M1ZE',
    contact: '9600787037',
    email: 'vvgiri210@gmail.com',
  },
};

const handleAgencyAddressChange = (e) => {
  const agencyAddressKey = e.target.value;
  setSelectedAgencyAddress(agencyAddressKey);
  setFormData((prevState) => ({
    ...prevState,
    AgencyAddress: agencyaddresses[agencyAddressKey] || {},
  }));
};

// Construct full address string for display
const fullAddress = selectedAgencyAddress
  ? `${agencyaddresses[selectedAgencyAddress].name}\n` +
    `${agencyaddresses[selectedAgencyAddress].address}\n` +
    `${agencyaddresses[selectedAgencyAddress].city}\n` +
    `${agencyaddresses[selectedAgencyAddress].state} - ${agencyaddresses[selectedAgencyAddress].postcode}\n` +
    `GSTIN/UIN: ${agencyaddresses[selectedAgencyAddress].gstin}\n` +
    `Contact: ${agencyaddresses[selectedAgencyAddress].contact}\n` +
    `E-Mail: ${agencyaddresses[selectedAgencyAddress].email}`
  : '';

  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    // Check if files were uploaded
    if (files && files.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0], // Store the first selected file as a Blob
      }));
    } else {
      // Handle text input changes
      setFormData((prevState) => {
        // Update the state with the new value
        const updatedState = {
          ...prevState,
          [name]: value, // Store the value from the text input
        };
  
        // Recalculate total price if relevant fields change
        if (name === "unitPrice" || name === "qty" || name === "tax") {
          updatedState.totalPrice = calculateTotalPrice(updatedState.unitPrice, updatedState.qty, updatedState.tax);
        }
        
        return updatedState;
      });
    }
  };
  
  // Add the handleImageUpload function here
const handleImageUpload = (event) => {
  const files = Array.from(event.target.files); // Convert FileList to Array
  const imageNames = files.map(file => file.name); // Get the names of the files
  setFormData((prevData) => ({
    ...prevData,
    uploadImage: imageNames, // Set the array of image names
  }));
};

  const handleGstVerification = async () => {
    if (formData.partyGst.length !== 15) {
      alert("Invalid GST number. Please enter a valid GST.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://appyflow.in/api/verifyGST?key_secret=C3PEUnyUKqbnUQIZ8vFuNeAsZZy2&gstNo=${formData.partyGst}`);
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const json = await response.json();

      const { taxpayerInfo } = json;
      const { adadr, pradr } = taxpayerInfo;

      setAdadr(adadr || []);
      setPradr(pradr || null);
      setTaxpayerInfo(taxpayerInfo);

      // Populate form with taxpayer information
      setFormData((prevState) => ({
        ...prevState,
        companyName: taxpayerInfo.tradeNam || '',
        address: pradr?.addr.bno + ', ' + pradr?.addr.st + ', ' + pradr?.addr.loc + ', ' + pradr?.addr.stcd + ', ' + pradr?.addr.dst + ', ' + pradr?.addr.pncd || '',
      }));

      setIsGstVerified(true);
      // setManualEntry(false);  // No manual entry needed if GST is verified
    } catch (error) {
      setError(error.message);
      // setManualEntry(true);  // Allow manual entry if GST verification fails
      alert("Please Provide Company Name and Address Manually");
    } finally {
      setLoading(false);
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create the PDF content in memory
    const doc = new jsPDF();
  
    // Set title
    doc.setFontSize(22);
    doc.setFont('helvetica', 'bold');
    doc.text('Estimate', 20, 20);
  
    let y = 30; // Start Y position for text
  
    // Set font for the body text
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
  
    // Add Agency Address only if selected
    if (selectedAgencyAddress) {
      const agencyAddress = agencyaddresses[selectedAgencyAddress];
      if (agencyAddress) {
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        doc.text('Agency Address', 20, y);
        y += 10;
  
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`${agencyAddress.name}`, 20, y);
        y += 8;
        doc.text(`${agencyAddress.address}`, 20, y);
        y += 8;
        doc.text(`${agencyAddress.city}`, 20, y);
        y += 8;
        doc.text(`${agencyAddress.state}, ${agencyAddress.postcode}`, 20, y);
        y += 8;
        doc.text(`${agencyAddress.gstin}`, 20, y);
        y += 8;
        doc.text(`${agencyAddress.contact}`, 20, y);
        y += 8;
        doc.text(`${agencyAddress.email}`, 20, y);
        y += 8;


      }
    }
  
    // Add taxpayer information only if GST is verified
    if (isGstVerified) {
      doc.setFontSize(16);
      doc.setFont('helvetica', 'bold');
      doc.text('Taxpayer Information', 20, y);
      y += 10;
  
      if (taxpayerInfo.lgnm) {
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`Legal Name: ${taxpayerInfo.lgnm}`, 20, y);
        y += 8;
      }
      if (taxpayerInfo.tradeNam) {
        doc.text(`Trade Name: ${taxpayerInfo.tradeNam}`, 20, y);
        y += 8;
      }
      if (taxpayerInfo.gstin) {
        doc.text(`GSTIN: ${taxpayerInfo.gstin}`, 20, y);
        y += 8;
      }
      if (taxpayerInfo.sts) {
        doc.text(`Status: ${taxpayerInfo.sts}`, 20, y);
        y += 12;
      }
  
      // Add Primary Address if it exists
      if (pradr) {
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        doc.text('Primary Address', 20, y);
        y += 10;
  
        if (pradr.addr) {
          doc.setFontSize(12);
          doc.setFont('helvetica', 'normal');
          doc.text(`${pradr.addr.bno || 'Not Provided'}, ${pradr.addr.st || 'Not Provided'}`, 20, y);
          y += 8;
          doc.text(`${pradr.addr.loc || 'Not Provided'}, ${pradr.addr.dst || 'Not Provided'}`, 20, y);
          y += 8;
          doc.text(`${pradr.addr.stcd || 'Not Provided'}, ${pradr.addr.pncd || 'Not Provided'}`, 20, y);
          y += 12;
        }
      }
  
      // Add Additional Addresses if they exist
      if (adadr && adadr.length > 0) {
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        doc.text('Additional Addresses', 20, y);
        y += 10;
  
        doc.setFontSize(12);
        adadr.forEach((address, index) => {
          if (y + 30 > doc.internal.pageSize.height) {
            doc.addPage(); // Add a new page if needed
            y = 20; // Reset Y position for the new page
          }
  
          doc.setFontSize(12);
          doc.setFont('helvetica', 'bold');
          doc.text(`Address ${index + 1}`, 20, y);
          y += 10;
  
          if (address.addr) {
            doc.setFontSize(12);
            doc.setFont('helvetica', 'normal');
            doc.text(`Nature of Business: ${address.ntr || 'Not Provided'}`, 20, y);
            y += 8;
            doc.text(`${address.addr.bno || 'Not Provided'}, ${address.addr.st || 'Not Provided'}`, 20, y);
            y += 8;
            doc.text(`${address.addr.loc || 'Not Provided'}, ${address.addr.dst || 'Not Provided'}`, 20, y);
            y += 8;
            doc.text(`${address.addr.stcd || 'Not Provided'}, ${address.addr.pncd || 'Not Provided'}`, 20, y);
            y += 12;
          }
        });
      } else {
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text('No additional addresses available', 20, y);
        y += 12;
      }
  
      // Add a new page for additional information if necessary
      doc.addPage();
      y = 20;
  
      doc.setFontSize(16);
      doc.setFont('helvetica', 'bold');
      doc.text('Additional Information', 20, y);
      y += 10;
    } else {
      // If GST is not verified, add manual entry information
      doc.setFontSize(16);
      doc.setFont('helvetica', 'bold');
      // doc.text('Manual Entry Information', 20, y);
      y += 10;
  
      if (formData.companyName) {
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`Customer Name: ${formData.companyName}`, 20, y);
        y += 8;
      }
      if (formData.address) {
        doc.text(`Address: ${formData.address}`, 20, y);
        y += 12;
      }
    }
  
    // // Add Customer Information
    // doc.setFontSize(16);
    // doc.setFont('helvetica', 'bold');
    // // doc.text('Customer Information', 20, y);
    // y += 10;
  
    if (formData.PhoneNumber) {
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(`Phone Number: ${formData.PhoneNumber}`, 20, y);
      y += 8;
    }
    if (formData.DeliveryContact) {
      doc.text(`Delivery Contact: ${formData.DeliveryContact}`, 20, y);
      y += 8;
    }
    if (formData.ShippingAddress) {
      doc.text(`Shipping Address: ${formData.ShippingAddress}`, 20, y);
      y += 10;
    }
  
    // // Add Product Information
    // doc.setFontSize(16);
    // doc.setFont('helvetica', 'bold');
    // // doc.text('Product Information', 20, y);
    // y += 10;
  
    if (formData.productModel) {
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(`Product Model: ${formData.productModel}`, 20, y);
      y += 8;
    }
    if (formData.description) {
      doc.text(`Description: ${formData.description}`, 20, y);
      y += 8;
    }
    if (formData.unitPrice) {
      doc.text(`Price (exclusive of tax): ${formData.unitPrice}`, 20, y); // Price added
      y += 8;
    }
    if (formData.qty) {
      doc.text(`Quantity: ${formData.qty}`, 20, y);
      y += 8;
    }
    if (formData.tax) {
      doc.text(`Tax (%): ${formData.tax}`, 20, y);
      y += 8; 
    }
    if (formData.hsn) {
      doc.text(`HSN Code: ${formData.hsn}`, 20, y);
      y += 8; 
    }
    if (formData.totalPrice !== undefined && formData.totalPrice !== null && !isNaN(formData.totalPrice)) {
      const formattedPrice = Number(formData.totalPrice).toFixed(2); // Ensure totalPrice is a number
      doc.text(`Total Price (Inclusive of tax): ${formattedPrice}`, 20, y);
      y += 10;
    } else {
      doc.text(`Total Price (Inclusive of tax): N/A`, 20, y); // Show N/A when totalPrice is not a valid number
      y += 8;
    }
    
    
  
    // // Add Payment and Shipping Information
    // doc.setFontSize(16);
    // doc.setFont('helvetica', 'bold');
    // // doc.text('Payment & Shipping Information', 20, y);
    // y += 10;
  
    if (formData.paymentMode || formData.customPaymentMode) {
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
    
      // Create a string that combines paymentMode and customPaymentMode
      let paymentInfo = 'Payment Mode: ';
      
      // Check if paymentMode is set
      if (formData.paymentMode) {
        paymentInfo += `${formData.paymentMode}`;
      }
    
      // Check if customPaymentMode is set and append it
      if (formData.customPaymentMode) {
        if (formData.paymentMode) {
          paymentInfo += ` (Custom: ${formData.customPaymentMode})`; // Format if paymentMode exists
        } else {
          paymentInfo += `${formData.customPaymentMode}`; // Only customPaymentMode if paymentMode does not exist
        }
      }
    
      // Add the combined payment info to the PDF
      doc.text(paymentInfo, 20, y);
      y += 8; // Update the y position for the next text
    }
    
    if (formData.shippingVehicle) {
      doc.text(`Shipping Vehicle: ${formData.shippingVehicle}`, 20, y); // Shipping Vehicle added
      y += 8;
    }
    if (formData.bookingMod) {
      doc.text(`Booking Mode: ${formData.bookingMod}`, 20, y); // Booking Mode added
      y += 8;
    }
    if (formData.bookingPlace) {
      doc.text(`Booking Place: ${formData.bookingPlace}`, 20, y); // Booking Place added
      y += 8;
    }
    if (formData.bookingTransport) {
      doc.text(`Booking Transport: ${formData.bookingTransport}`, 20, y); // Booking Transport added
      y += 12;
    }
  
    // Function to add images to the PDF
    const addImagesToPDF = async (doc, images) => {
      for (const imageName of images) {
        const imgUrl = `https://vinuvehasheneagency.com/api${imageName}`; // Construct image URL
  
        const img = new Image();
        img.src = imgUrl; // Set the image source to the URL
  
        try {
          await new Promise((resolve, reject) => {
            img.onload = () => {
              const imgWidth = img.width;
              const imgHeight = img.height;
  
              const pageWidth = doc.internal.pageSize.getWidth();
              const pageHeight = doc.internal.pageSize.getHeight();
  
              const maxWidth = pageWidth - 40;
              const maxHeight = pageHeight - 40;
              let displayWidth = imgWidth;
              let displayHeight = imgHeight;
  
              // Resize to fit within the PDF
              if (imgWidth > maxWidth || imgHeight > maxHeight) {
                if (imgWidth / maxWidth > imgHeight / maxHeight) {
                  displayWidth = maxWidth;
                  displayHeight = maxWidth * (imgHeight / imgWidth);
                } else {
                  displayHeight = maxHeight;
                  displayWidth = maxHeight * (imgWidth / imgHeight);
                }
              }
  
              // Center the image on a new page
              doc.addPage();
              const xOffset = (pageWidth - displayWidth) / 2; // Centering the image
              const yOffset = (pageHeight - displayHeight) / 2; // Centering the image
              doc.addImage(img.src, 'JPEG', xOffset, yOffset, displayWidth, displayHeight);
              resolve(); // Resolve the promise once the image is added
            };
  
            img.onerror = (error) => {
              reject('Image load error: ' + error);
            };
          });
        } catch (error) {
          console.error(error); // Log the error for any failed image loads
          // You may choose to skip the image and continue, or handle it differently
        }
      }
  
      // Save the PDF after all images are added
      doc.save(`estimate_${new Date().toISOString()}.pdf`);
    };
  
    // Usage example
    if (formData.uploadImage && formData.uploadImage.length > 0) {
      addImagesToPDF(doc, formData.uploadImage);
    } else {
      // Save the PDF without images if none are provided
      doc.save(`estimate_${new Date().toISOString()}.pdf`);
    }
  
    // Clear the form and reset the formData state
    setFormData(initialFormData);
    setSelectedAgencyAddress(''); // Reset the selected agency address
  
    // Optionally clear other states if necessary
    setSearchQuery('');
    setSelectedProduct(null);
    setError(null);
    setLoading(false);
  };
  


  // Function to calculate total price (inclusive of tax)
  const calculateTotalPrice = (unitPrice, qty, tax) => {
    const numericUnitPrice = parseFloat(unitPrice) || 0;  // Convert to number, default to 0
    const numericQty = parseInt(qty) || 0;                // Convert to integer, default to 0
    const numericTax = parseFloat(tax) || 0;              // Convert to number, default to 0

    // Calculate total price excluding tax
    const subtotal = numericUnitPrice * numericQty;

    // Calculate total price including tax
    const totalWithTax = subtotal + (subtotal * (numericTax / 100));
    return totalWithTax.toFixed(2); // Return formatted total price
  };
 

const handlePaymentModeChange = (e) => {
  const { value } = e.target;
  setFormData((prevState) => ({
    ...prevState,
    paymentMode: value,
    customPaymentMode: '',  // Reset custom input if a selection is made
  }));
};

const handleCustomPaymentModeChange = (e) => {
  const { value } = e.target;
  setFormData((prevState) => ({
    ...prevState,
    customPaymentMode: value,
    paymentMode: '',  // Reset dropdown if manual input is made
  }));
};

 // Calculate total price (inclusive of tax) based on current inputs
 const totalWithTax = calculateTotalPrice(formData.unitPrice, formData.qty, formData.tax);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="form-wrapper p-5 shadow-lg rounded">
          <h1 className="text-center mb-4">Estimate Generator</h1>
          <form>
            <div className="row">
            
                    {/* Agency Address */}
                  <div className="form-group col-12 mb-4">
                    <label htmlFor="agencyAddress" className="form-label">Select Agency Address</label>
                    <select
                      className="form-control tooltip-target"
                      name="agencyAddress"
                      id="agencyAddress"
                      value={selectedAgencyAddress}
                      onChange={handleAgencyAddressChange}
                    >
                      <option value="">Select an address</option>
                      <option value="address1">
                        Address 1: VINUVEHASHENE AGENCY
                      </option>
                      <option value="address2">
                        Address 2: NDV COOLING EQUIPMENTS
                      </option>
                    </select>
                  </div>

              {/* Display Full Address */}
              {fullAddress && (
                <div className="form-group col-12 mb-4">
                  <label className="form-label">Full Address</label>
                  <textarea
                    className="form-control"
                    rows={6} // Adjust the number of rows as needed
                    value={fullAddress}
                    readOnly
                  />
                </div>
              )}

              {/* GST Number */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="partyGst" className="form-label">Party GST Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="partyGst"
                  name="partyGst"
                  value={formData.partyGst}
                  onChange={handleChange}
                />
                <small className="form-text text-muted">Provide your GST number for verification</small>
                <button
                  className="btn btn-primary mt-2"
                  type="button" // changed to type="button" to avoid form submission
                  onClick={handleGstVerification}
                  disabled={loading}
                >
                  {loading ? 'Verifying...' : 'Verify GST'}
                </button>
                {error && <p className="text-danger mt-2">{error}</p>}
              </div>

              {/* Company Name and Address */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="companyName" className="form-label">Customer Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-12 mb-4">
                <label htmlFor="address" className="form-label">Address</label>
                <textarea
                  className="form-control"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  rows={4} // Increase the number of rows as needed
                  placeholder="Enter full address here..."
                />
              </div>

              {/* Phone Number */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="PhoneNumber" className="form-label">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="PhoneNumber"
                  name="PhoneNumber"
                  value={formData.PhoneNumber}
                  onChange={handleChange}
                />
              </div>

              {/* Delivery Contact */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="DeliveryContact" className="form-label">Delivery Contact</label>
                <input
                  type="text"
                  className="form-control"
                  id="DeliveryContact"
                  name="DeliveryContact"
                  value={formData.DeliveryContact}
                  onChange={handleChange}
                />
              </div>

              {/* Shipping Address */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="ShippingAddress" className="form-label">Shipping Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="ShippingAddress"
                  name="ShippingAddress"
                  value={formData.ShippingAddress}
                  onChange={handleChange}
                />
              </div>

              {/* Product Search */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="productSearch" className="form-label">Search Product</label>
                <input
                  type="text"
                  id="productSearch"
                  className="form-control"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search for a product..."
                />
              </div>

              {/* Display Suggestions */}
              {searchQuery && filteredProducts.length > 0 && (
                <ul className="list-group col-12 mb-4">
                  {filteredProducts.map((product) => (
                    <li
                      key={product.id}
                      className="list-group-item list-group-item-action"
                      onClick={() => handleProductSelect(product)}
                      style={{ cursor: 'pointer' }}
                    >
                      {product.product_name}
                    </li>
                  ))}
                </ul>
              )}

              {/* Display Selected Product Details */}
              {selectedProduct && (
                <>
                  <div className="form-group col-12 mb-4">
                    <label htmlFor="description" className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      value={formData.description}
                      readOnly
                    />
                  </div>

                  {/* Display Uploaded Images */}
                  <div className="form-group col-12 mb-4">
                    {formData.uploadImage && formData.uploadImage.length > 0 ? (
                      <>
                        <label htmlFor="productImages" className="form-label">Product Images</label>
                        <div className="d-flex flex-wrap">
                          {formData.uploadImage.map((imageName, index) => (
                            <img
                              key={index}
                              src={`https://vinuvehasheneagency.com/api${imageName}`}
                              alt={`Product ${index + 1}`}
                              className="img-thumbnail m-2"
                              style={{ maxWidth: '200px' }}
                            />
                          ))}
                        </div>
                      </>
                    ) : (
                      <p>No images available.</p>
                    )}
                  </div>
                </>
              )}


              {/* Unit Price (Exclusive of Tax) */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="unitPrice" className="form-label">Unit Price (exclusive of tax)</label>
                <input
                  type="number"  // Use number for better input handling
                  className="form-control"
                  id="unitPrice"
                  name="unitPrice"
                  value={formData.unitPrice}
                  onChange={handleChange}
                />
              </div>

              {/* Quantity */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="qty" className="form-label">Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  id="qty"
                  name="qty"
                  value={formData.qty}
                  onChange={handleChange}
                />
              </div>

              {/* Tax */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="tax" className="form-label">Tax (%)</label>
                <input
                  type="number"
                  className="form-control"
                  id="tax"
                  name="tax"
                  value={formData.tax}
                  onChange={handleChange}
                />
              </div>

              {/* HSN Code */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="hsn" className="form-label">HSN Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="hsn"
                  name="hsn"
                  value={formData.hsn}
                  onChange={handleChange}
                />
              </div>              

              {/* Total Price (Inclusive of Tax) */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="totalPrice" className="form-label">Total Price (Inclusive of Tax)</label>
                <input
                  type="text"
                  className="form-control"
                  id="totalPrice"
                  name="totalPrice"
                  value={formData.totalPrice}
                  readOnly
                />
              </div>


              {/* Payment Mode */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="paymentMode" className="form-label">Payment Mode</label>
                <div className="input-group">
                  <select
                    className="form-control"
                    name="paymentMode"
                    id="paymentMode"
                    value={formData.paymentMode}
                    onChange={handlePaymentModeChange}
                  >
                    <option value="">Select Payment Mode</option>
                    <option value="Cash">Cash</option>
                    <option value="UPI">UPI</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Net Banking">Net Banking</option>
                  </select>
                  <input
                    type="text"
                    className="form-control"
                    id="customPaymentMode"
                    name="customPaymentMode"
                    placeholder="Or enter your own..."
                    value={formData.customPaymentMode || ''}
                    onChange={handleCustomPaymentModeChange}
                  />
                </div>
              </div>


              {/* Shipping Vehicle */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="shippingVehicle" className="form-label">Shipping Vehicle</label>
                <input
                  type="text"
                  className="form-control"
                  id="shippingVehicle"
                  name="shippingVehicle"
                  value={formData.shippingVehicle}
                  onChange={handleChange}
                />
              </div>

              {/* Booking Mode */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="bookingMode" className="form-label">Booking Mode</label>
                <input
                  type="text"
                  className="form-control"
                  id="bookingMode"
                  name="bookingMode"
                  value={formData.bookingMode}
                  onChange={handleChange}
                />
              </div>

              {/* Booking Place */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="bookingPlace" className="form-label">Booking Place</label>
                <input
                  type="text"
                  className="form-control"
                  id="bookingPlace"
                  name="bookingPlace"
                  value={formData.bookingPlace}
                  onChange={handleChange}
                />
              </div>

              {/* Booking Transport */}
              <div className="form-group col-12 mb-4">
                <label htmlFor="bookingTransport" className="form-label">Booking Transport</label>
                <input
                  type="text"
                  className="form-control"
                  id="bookingTransport"
                  name="bookingTransport"
                  value={formData.bookingTransport}
                  onChange={handleChange}
                />
              </div>


              {/* Submit Button */}
              <div className="col-12">
                <button 
                  type="button" 
                  className="btn btn-success" 
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default App;
