import React from 'react';
import { useLocation } from 'react-router-dom';
import './EstimatePage.css';

function EstimatePage() {
  const location = useLocation();
  const { pdfUrl, imageUrl, uploadedPdfUrl } = location.state || {};

  const handleWhatsAppShare = () => {
    // Create a message with a link to the PDF file
    const message = `Check out this order form: ${pdfUrl}`;

    // Open WhatsApp with the message
    window.open(`http://wa.me/?text=${encodeURIComponent(message)}`, '_blank');
  };

  const handleEmailShare = () => {
    // Create an email message with a link to the PDF file
    const subject = 'Order Form';
    const body = `Check out this order form: ${pdfUrl}`;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <div className="pdf-container">
      <h2>Estimate</h2>
      {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px" title="Estimate PDF Document"></iframe>}
      {imageUrl && (
        <div>
          <h3>Uploaded Image</h3>
          <img src={imageUrl} alt="Uploaded" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
      )}
      {uploadedPdfUrl && (
        <div>
          <h3>Uploaded PDF</h3>
          <iframe src={uploadedPdfUrl} width="100%" height="600px" title="Uploaded PDF Document"></iframe>
        </div>
      )}
      <div className="share-buttons">
        <button onClick={handleWhatsAppShare}>Share on WhatsApp</button>
        <button onClick={handleEmailShare}>Share via Email</button>
      </div>
    </div>
  );
}

export default EstimatePage;
