import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Signup.css'; // Import custom CSS

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    setIsLoading(true); // Start loading
    try {
      const response = await axios.post('https://vinuvehasheneagency.com/api/register', { email, password });
      console.log('Response from server:', response.data); // Log the response
      setMessage('Account created successfully.');
      navigate('/login');
    } catch (error) {
      // Handle error messages
      if (error.response) {
        setMessage(error.response.data.error || 'Error creating account');
        console.error('Error response:', error.response.data); // Log error response
      } else if (error.request) {
        setMessage('No response from the server');
        console.error('No response:', error.request); // Log request details
      } else {
        setMessage('Error: ' + error.message);
        console.error('Error message:', error.message); // Log error message
      }
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="signup-container">
      <h2>Signup</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm Password:</label>
          <input
            type="password"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-success btn-block" disabled={isLoading}>
          {isLoading ? 'Creating...' : 'Signup'}
        </button>
      </form>
    </div>
  );
}

export default Signup;
