import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'; // Import custom CSS

function Dashboard() {
  const navigate = useNavigate();

  return (
    <div className="dashboard-container d-flex justify-content-center align-items-center">
      <div className="text-center">
        <h2 className="mb-4">Dashboard</h2>
        <div className="button-container mb-2">
          <button className="btn btn-primary animated-button" onClick={() => navigate('/estimate')}>
            Estimate
          </button>
        </div>
        <div className="button-container">
          <button className="btn btn-secondary animated-button" onClick={() => navigate('/add-product')}>
            Product
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
